import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { AuthActionLogout } from "@protego/sdk/actions/auth";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import Link from "@protego/sdk/UI/Link/Link";
import { fetchMe } from "actions/me";
import clsx from "clsx";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "reactstrap/es/Dropdown";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import { avatarString } from "util/string";
import { ReactComponent as EnvelopeIcon } from "../../assets/images/EnvelopeIcon.svg";
import Utils from "util/index";
import styles from "./UserInfo.module.scss";
import { userSignOut } from "actions/Auth";
import {
  WHITE
} from "constants/ThemeColors";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: WHITE
  },
  avatarLarge: {
    width: theme.typography.pxToRem(70),
    height: theme.typography.pxToRem(70)
  },
  textBody: {
    color: theme.palette.text.body
  }
}));

const UserInfo = () => {
  const dispatch = useDispatch(),
    classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleRequestClose = () => {
    setOpen(!open);
  };

  async function handleLogout() {
    await dispatch(userSignOut());
    await dispatch(AuthActionLogout());
  }

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);
  const { me } = useSelector(state => state.me);
  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Dropdown isOpen={open} toggle={handleRequestClose}>
        <DropdownToggle
          className={"d-inline-block"}
          tag={"span"}
          data-toggle={"dropdown"}
        >
          <Avatar
            alt="..."
            className={clsx(classes.avatar)}
            style={{ backgroundColor: me.colorCode }}
            onClick={handleRequestClose}
          >
            {me.firstName || me.lastName
              ? avatarString(me.firstName, me.lastName)
              : ""}
          </Avatar>
        </DropdownToggle>
        <DropdownMenu className={styles.Dropdown} right>
          <div className={styles.Profile}>
            <div className={"d-flex align-items-center"}>
              <Avatar
                alt="..."
                className={clsx(classes.avatar, classes.avatarLarge)}
                style={{ backgroundColor: me.colorCode }}
              >
                {me.firstName || me.lastName
                  ? avatarString(me.firstName, me.lastName)
                  : ""}
              </Avatar>
              <div>
                <Typography className={classes.textBody} variant={"body1"}>
                  {get(me, "firstName")} {get(me, "lastName")}
                </Typography>
                <Typography color={"textSecondary"} variant={"body1"}>
                  {Utils.getRoleText(get(me, "role"))}
                </Typography>
                <Typography color={"textSecondary"} variant={"body1"}>
                  <EnvelopeIcon /> {get(me, "email")}
                </Typography>
              </div>
            </div>
            <MenuItem
              component={Link}
              to={"/app/staff/profile"}
              onClick={handleRequestClose}
            >
              <IntlMessages id="popup.profile" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                handleLogout();
              }}
            >
              <IntlMessages id="popup.logout" />
            </MenuItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
