import makeStyles from "@material-ui/core/styles/makeStyles";
import { toRem } from "@protego/sdk/utils/measurements";
import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Audit from "./Audit";
import Billing from "./Billing";
import CompanyPage from "./Company";
import Dashboard from "./Dashboard";
import PageNotFound from "./ErrorPages/routes/404";
import Package from "./PackagePage";
import ProfilePage from "./ProfilePage/ProfilePage";
import Report from "./Report";
import SettingsPage from "./SettingsPage/SettingsPage";
import Staff from "./Staff";
import Transaction from "./Transaction";

const useStyles = makeStyles(theme => ({
  appWrapper: {
    padding: `${30 |> toRem} ${40 |> toRem}`,
    height: "100%"
  }
}));

const routes = [
  ["/dashboard", Dashboard],
  ["/staff/profile", ProfilePage],
  ["/companies", CompanyPage],
  ["/staff", Staff],
  ["/setting", SettingsPage],
  ["/package", Package],
  ["/billing", Billing],
  ["/transaction", Transaction],
  ["/report", Report],
  ["/audit", Audit],
  ["/page-not-found", PageNotFound]
];

const Routes = ({ match }) => {
  const classes = useStyles();
  return (
    <div className={classes.appWrapper}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        {routes.map(([path, component]) => (
          <Route key={path} path={match.url + path} component={component} />
        ))}
        <Route exact path="/app">
          <Redirect to="/app/dashboard" />
        </Route>
        <Redirect exact to={`${match.url}/page-not-found`} />
      </Switch>
    </div>
  );
};
export default withRouter(Routes);
