import moment from "moment";
import { ROLE_ADMINISTRATOR, ROLE_USER } from "../constants/ActionTypes";

const covertToNormalWord = str => {
  // filter uppercase
  str = str.toLowerCase();
  // filter lower case
  str = str
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    .replace(/ì|í|ị|ỉ|ĩ/g, "i")
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    .replace(/đ/g, "d")
    .replace(/́/g, "")
    .trim();
  return str;
};

const getFirstString = string => {
  if (string) {
    return string.substr(0, 1);
  }
  return "";
};

const getRoleText = string => {
  if (string === "STAFF") {
    return ROLE_USER;
  }
  if (string === "USER") {
    return ROLE_USER;
  }
  if (string === "SUPERADMIN") {
    return ROLE_ADMINISTRATOR;
  }
  if (string === "ADMINISTRATOR") {
    return ROLE_ADMINISTRATOR;
  }
  return "";
};

const convertToDate = date => {
  if (date) return moment(date).format("ll");
  return;
};

const convertToTime = time => {
  if (time) return moment(time).format("HH:mm:ss a");
  return;
};

const getUrlParams = url =>
  `${url}?`
    .split("?")[1]
    .split("&")
    .reduce(
      (params, pair) =>
        ((key, val) => (key ? { ...params, [key]: val } : params))(
          ...`${pair}=`.split("=").map(decodeURIComponent)
        ),
      {}
    );
const formatDateTime = dateTime => {
  if (dateTime) return moment(dateTime).format("DD/MM/YY HH:mm");
};

export default {
  covertToNormalWord: covertToNormalWord,
  getFirstString: getFirstString,
  getRoleText: getRoleText,
  convertToDate: convertToDate,
  convertToTime: convertToTime,
  getUrlParams: getUrlParams,
  formatDateTime: formatDateTime
};
