import { Button, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import JRCard from "@protego/sdk/UI/JRCard/JRCard";
import Link from "@protego/sdk/UI/Link";
import PageHeading from "@protego/sdk/UI/PageHeading/PageHeading";
import { usePrompt } from "@protego/sdk/UI/PromptDialog";
import withPagination from "@protego/sdk/UI/withPagination";
import {
  addNewStaffUser,
  deleteStaffUser,
  setStaffLocked,
  staffList
} from "actions/Staff";
import CustomDataTable from "components/CustomDataTable";
import ExportCSV from "components/ExportCSV";
import LongMenu from "components/menuNPaper/long/LongMenu";
import SearchBox from "components/SearchBox";
import UserAvatar from "components/UserAvatar";
import { STAFF_HEADER_CSV } from "config/exportCsv";
import { DELETE } from "constants/ActionTypes";
import { filter } from "lodash";
import React, { useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import NotificationManager from "react-notifications/lib/NotificationManager";
/**
 * Redux
 */
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDate, LONG_DATE_TIME } from "util/date";
import Utils from "util/index";
import IntlMessages from "util/IntlMessages";
import { parseQuery } from "util/string";
/**
 * Library
 */
import AddNewStaff from "./addstaff";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tablePaddingTop: {
    marginTop: theme.typography.pxToRem(20)
  },
  icon: {
    color: "#E0E0E0",
    height: "100%"
  },
  modalContent: {
    padding: theme.mixins.paddingToRem(75, 170),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500
  },
  modalButton: {
    width: theme.typography.pxToRem(174),
    height: theme.typography.pxToRem(50)
  }
}));

const StaffPage = ({ location, match, paginationParams }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddNewStaff, setOpenAddNewStaff] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const staffState = useSelector(state => state.staff.listStaff);

  const deletePrompt = usePrompt({
    title: <IntlMessages id={"staff.Delete.Dialog.Title"} />,
    message: <IntlMessages id={"staff.Delete.Dialog.Content"} />,
    actions: [{ value: "delete", color: "primary" }, "cancel"],
    classes: {
      content: classes.modalContent,
      action: classes.modalButton
    }
  });

  useEffect(() => {
    dispatch(staffList(paginationParams));
    // eslint-disable-next-line
  }, [paginationParams]);
  /**
   * Add new staff
   */
  const onPressAddNewStaff = () => {
    setOpenAddNewStaff(!openAddNewStaff);
  };
  /**
   * Submit add new staff
   */
  const onPressSubmit = async item => {
    setOpenAddNewStaff(false);
    try {
      await dispatch(addNewStaffUser(item));
      NotificationManager.success("", "Success", 3000);
      dispatch(staffList(paginationParams));
    } catch (err) {
      NotificationManager.error("", "Failed", 3000);
    }
  };
  /**
   * Selected option
   */

  const onSelectOption = async (staffData, type) => {
    const { location } = history;

    if (type === DELETE) {
      const response = await deletePrompt();
      if (response === "delete") {
        let query = parseQuery(location.search);
        dispatch(deleteStaffUser(staffData.id))
          .then(() => {
            dispatch(staffList(query));
          })
          .catch(err => {
            NotificationManager.error("", "Error", 3000);
          });
      }
      return;
    }
    history.push({
      pathname: match.path + staffData.id,
      state: { staffData, type }
    });
  };
  /**
   * Locked staff
   */
  const setRowSwitchAction = (value, isSwitch) => {
    let obj = { locked: isSwitch, id: value };
    dispatch(setStaffLocked(obj, paginationParams.page, paginationParams.size));
  };

  let filterExport = filter(selected, function(o) {
    o.staffName = o.firstName + " " + o.lastName;
    o.roleName = o.role === "SUPERADMIN" ? "	Administrator" : "	User";
    o.createdByExport =
      (o.createdBy && o.createdBy.firstName ? o.createdBy.firstName : "") +
      " " +
      (o.createdBy && o.createdBy.lastName ? o.createdBy.lastName : "");
    o.createdAtExport = formatDate(o.createdAt, LONG_DATE_TIME);
    o.lastAccessed = formatDate(o.updatedAt, LONG_DATE_TIME);
    o.lockedExport = o.locked === true ? "False" : "True";
    return o;
  });

  return (
    <>
      <NotificationContainer />
      <PageHeading
        title={<IntlMessages id={"staff.name"} />}
        match={match}
        titleButton={
          <Button
            className="mr-0"
            variant={"contained"}
            color={"primary"}
            onClick={onPressAddNewStaff}
            startIcon={<AddIcon />}
          >
            <IntlMessages id={"staff.addnew"} />
          </Button>
        }
      />

      <AddNewStaff
        key="addStaff"
        open={openAddNewStaff}
        onPress={onPressAddNewStaff}
        onPressSubmit={onPressSubmit}
      />
      <JRCard>
        <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
          <div className="d-flex" />
          <div className="d-flex">
            <div className="company-list-search mr-2">
              <SearchBox styleName="d-none d-lg-block" />
            </div>
            <div className="ml-2">
              <ExportCSV
                nameFileExport="staff"
                dataExport={filterExport}
                headersExportCSV={STAFF_HEADER_CSV}
              />
            </div>
          </div>
        </div>
        <CustomDataTable
          data={staffState}
          columnData={{
            firstName: {
              label: "Staff Name",
              sort: true,
              style: { width: "22%" },
              renderCell: (v, item) => (
                <div className="d-flex align-items-center">
                  <UserAvatar user={item} />
                  <Link
                    to={match.path + item.id}
                    style={{ color: "#00bcd4" }}
                    className="ml-2"
                  >
                    {item.firstName + " " + item.lastName}
                  </Link>
                </div>
              )
            },
            id: {
              style: { width: "15%" },
              label: "Staff ID"
            },

            createdAt: {
              label: "Created By",
              sort: true,
              style: { width: "15%" },
              renderCell: (v, item) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row"
                  }}
                >
                  <div style={{ flex: 1 }} className={"mr-2"}>
                    {item.createdBy && <UserAvatar user={item.createdBy} />}
                  </div>
                  <div style={{ flex: 4 }}>
                    <div>
                      <span className="text-primary table-text">
                        {Utils.convertToDate(item.createdAt)}
                      </span>
                    </div>
                    <div>
                      <span className="text-primary table-text">
                        {Utils.convertToTime(item.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              )
            },
            lastAccessed: {
              sort: true,
              label: "Last Accessed",
              style: { width: "12%" },
              renderCell: (v, { isVerified }) => {
                if (isVerified === false) {
                  return <div>Not activated yet</div>;
                } else if (v == null) {
                  return <div>Not sign in yet</div>;
                }

                return (
                  <div>
                    <div>
                      <text className="text-primary table-text">
                        {Utils.convertToDate(v)}
                      </text>
                    </div>
                    <div>
                      <text className="text-primary table-text">
                        {Utils.convertToTime(v)}
                      </text>
                    </div>
                  </div>
                );
              }
            },
            locked: {
              label: "Active",
              align: "center",
              style: { width: "10%" },
              renderCell: (v, item) => (
                <Switch
                  onClick={() => setRowSwitchAction(item.id, v)}
                  checked={!v}
                />
              )
            },
            actions: {
              label: "Actions",
              align: "center",
              style: { width: "15%" },
              renderCell: (v, item) => (
                <LongMenu
                  data={[
                    { id: 1, label: "View" },
                    { id: 2, label: "Edit" },
                    { id: 3, label: "Delete" }
                  ]}
                  id={item}
                  onSelected={(type, item) => onSelectOption(item, type)}
                />
              )
            }
          }}
          options={{
            selectable: true,
            selections: selected,
            onSelected: setSelected
          }}
        />
      </JRCard>
    </>
  );
};

export default withPagination(StaffPage);
